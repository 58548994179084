interface AppData {
  whatIfImages: {
    numberOfCards: number;
    descriptions: {
      [key: string]: string;
    };
  };
  whatIfText: string[];
  waitWhy: string[];
}

export const appData: AppData = {
  //Black Cards
  whatIfImages: {
    numberOfCards: 150,
    descriptions: {
      "02": "Like chess pieces frozen in monochrome meditation, solitary figures inhabit a cafeteria's quiet battlefield, where empty chairs wait as silent observers.",
      "03": "Blank yellow sentinels stand at attention, each sticky square a canvas awaiting tomorrow's thoughts, arranged like windows into possibility's empty rooms.",
      "04": "Young fury meets mechanical ear, a black-and-white duet where childhood's raw voice challenges the microphone's metal patience.",
      "05": "Nature's broken mirror scatters across dark waters, where ice islands drift like lost puzzle pieces seeking their arctic home.",
      "06": "Metal giants bend their orange necks to feast on steel-boxed treasures, while mountain guardians watch their mechanical ballet against coastal skies.",
      "07": "Like migrating birds in mechanical plumage, cyclists weave their synchronized dance across concrete plains, numbers pinned like temporary names to their racing wings",
      "08": "Time machines in metal skin lie dormant, their cyclopean eyes holding decades of frozen light, waiting to awaken memories through mechanical hearts.",
      "09": "Summer's blush ripens in velvet spheres, while gentle hands perform nature's ancient harvest dance beneath leaves dappled with golden light.",
      10: "Human constellations map their stories in a universe of threads, where every intersection marks a thought connected across white space.",
      11: "Memory's ribbon unspools in amber light, each perforation a heartbeat of time, stories dancing between sprocket holes like captured dreams.",
      12: "Like children's dreams cast in metallic glass, balloon animals evolve into modern art, where sapphire dogs and silver rabbits mirror gallery lights",
      13: "A digital explorer stands at reality's edge, wearing alphabet armor while pointing into virtual worlds through darkness's rectangular window.",
      14: "A plastic rainbow prepares for battle, where fuschia armor meets sunshine weapon in a pristine arena of mint-colored possibility.",
      15: "Black-clad figures float like musical notes on turquoise sheet music, their surfboards conducting an aquatic symphony beneath heaven's spotlight.",
      16: "Nature's perfect sphere balances on time's pedestal, wearing summer's blush while its vine crown reaches for remembered sunlight.",
      17: "A rainbow of sound pedals awaits bare feet's command, like a musical traffic light system guiding electric dreams between amplifier and guitar.",
      18: "A close-up view of a small number of light brown capsules with a transparent yellow liquid, set against a bright yellow background",
      19: "Metal sentinels stand in formation, their silver crowns poised above crystal vessels waiting to dispense life's invisible mysteries.",
      20: "Ocean's glass curtain curves toward heaven, weaving aqua silk and diamond spray into nature's perfect architectural arch at sunset's theater.",
      21: "Knowledge spirals like DNA strands in this paper galaxy, where a lone explorer reaches for wisdom among infinite shelved constellations.",
      22: "Stone nightmares take flight in Gothic grace, where medieval fears cast in modern concrete spread leather wings against infinity's blank canvas.",
      23: `Electric dreams flow through azure veins, where creativity's pulse beats in neon arteries against night's velvet skin.`,
      24: "Desert writes its autobiography in golden ripples, each ridge a sentence in time's endless manuscript carved by wind's patient pen.",
      25: "Night's heart burns bright in wilderness temple, where flame dancers perform ancient rituals for stars while darkness holds its breath.",
      26: "Frozen serpents sleep in winter's embrace, their turquoise coils dreaming of summer screams and laughter now buried beneath snow's silence.",
      27: "Joy's orchestra conducts itself, where raised hands become batons and smiles form symphonies, each face a note in humanity's spontaneous song.",
      28: "Paradise drowns in plastic waves, where consumption's tide meets nature's shore, leaving civilization's footprints on beauty's azure horizon.",
      29: "Ghost towers fade like memories through pollution's veil, their concrete bones wrapped in smog's shroud while the sky holds its breath.",
      30: `A concrete dragon exhales modernity's breath, its smoke signals spelling progress's price against twilight's conscience.`,
      31: "Steel giants stand sentinel in monochrome majesty, their glass scales reflecting shadows while concrete canyons carve paths through urban dreams.",
      32: "Like a pearl in the cosmic oyster, the moon's cratered face tells ancient stories of celestial impacts, its grey-white surface a cosmic memoir.",
      33: "Silent sentinels draped in winter's white cloak, these peaks pierce through clouds like ancient dragons emerging from misty dreams.",
      34: "A velvet-clad warrior in miniature, this jumping spider faces the world with eight-eyed intensity, nature's tiny champion ready for battle.",
      35: "A gentle philosopher in fur, this cottontail pauses to savor life's simple pleasures, finding poetry in a single wildflower.",
      36: "In the image, the woman with a confident smile appears to be the face of positivity and self-love, standing in front of a building that could symbolize her personal growth and transformation",
      37: "Like two notes finding perfect harmony, the newlyweds merge into one melody against the pastoral symphony, their love flowing like wind through wheat.",
      38: "Ancient philosopher trapped in ebony fur, the gorilla's eyes hold millennia of jungle wisdom, each wrinkle a chapter of primal knowledge.",
      39: "Urban hipster in canine form, this pug's solemn gaze and yellow beanie speak of coffee shops and contemplating life's deeper mysteries",
      40: "Dancing with liquid glass, the surfer becomes one with nature's pulse, riding emerald dreams through the ocean's liquid poetry",
      41: "A monochrome ballet of strategy and strength, these fighters weave shadows and light into a dance of disciplined precision",
      42: "Like a ballerina's tutu caught in sweet rain, this cake wears pink drips and rainbow confetti, crowned with cream clouds and cherry jewels.",
      43: "A serene warrior in meditation, her closed eyes and pressed palms embody the perfect balance between strength and spiritual grace.",
      44: `Urban poetry scrawled across metal canvas, where digital life meets street art, a commentary on modern connection seeking validation.`,
      45: "Standing at art's threshold, these sneakered feet pause before yellow promise, like a traveler at dawn's door awaiting adventure.",
      46: "A kaleidoscope's inner workings laid bare, geometric shapes dance in vibrant hues, building impossible staircases of color and light",
      47: "Like migrating birds in formation, dancers weave through space, their flowing dresses catching shadows as they carve ancient patterns in darkness.",
      48: "Through golden frames, time stands frozen in peasant brushstrokes, while a modern witness glimpses yesterday's quiet dignity through van Gogh's eyes.",
      49: "A mechanical sentinel in pale blue, this vintage Alfa sewing machine stands ready to weave memories, its dials marking time's patient stitches.",
      50: "Like mountains cradling wisdom, two fortune cookie fragments hold destiny's whispers between them, floating on sky-blue possibility.",
      51: "A trio of cups performs their morning ballet, hearts drawn in foam telling stories while hands orchestrate this caffeinated communion.",
      "52": "Like a lonely firefly in twilight's embrace, the street musician casts golden notes into shadows, his guitar a beacon for wandering souls.",
      "53": "Rainbow dreams take flight in desert skies, each balloon a floating jewel in nature's crown, carrying wishes on morning's breath.",
      "54": "A time-worn sentinel of stories, the Underwood typewriter basks in amber light, waiting to transform silence into symphonies of words.",
      "55": "Nature's stained-glass dancers, these painted ladies pirouette between petals, their wings telling tales in orange and ebony brushstrokes.",
      "56": "A sanctuary of stories bathed in honeyed light, where books stand guard like golden soldiers, protecting dreams between their pages.",
      "57": "Like a golden symphony in Tuscany's heart, vineyard rows conduct sunlight's orchestra while cypress trees stand as nature's metronomes marking eternal time.",
      "58": "A white chariot of childhood dreams, this ice cream truck glides through suburban memories, carrying sweetness like a traveling happiness merchant.",
      "59": "Clay spins stories beneath clay-stained fingers, as ancient wisdom flows through hands that shape earth into vessels of tomorrow's memories.",
      "60": "A secret garden in concrete canyons, where nature reclaims urban heights, creating an emerald path between steel mountains toward freedom.",
      "61": "Nature's cathedral stands in misty reverence, where moss-draped pillars guide wandering souls through ethereal green chambers of contemplation.",
      "62": "Time's stone portals frame autumn's story, where forgotten rails wear fallen leaves like copper coins, each arch a chapter in memory's infinite book.",
      "63": "A psychedelic phoenix soars above earthbound dreams, its rainbow wings painting freedom across ocean breezes while footprints mark the path below.",
      "64": "A treasure chest of cultural artistry, where metal blooms into silver rivers and golden suns, each necklace telling stories of ancient craftsmanship.",
      "65": "Nature's amphitheater hosts twilight's performance, where golden rays conduct a symphony of shadows while humans gather like fireflies at dusk.",
      "66": "An underwater garden party where coral branches reach like painted fingers, hosting fish that dance through their vibrant ballroom like confetti.",
      "67": "Like time's own music box, the turntable basks in golden hour light, while houseplants conduct a silent symphony of shadows and sunbeams.",
      "68": "Nature's paintbox spills across wooden tables, each fruit a jewel in morning light, creating an edible rainbow in this merchant's outdoor gallery.",
      "69": "A stone sentinel stands guard between earth and sea, its weathered bones holding centuries of stories while waves whisper ancient secrets below.",
      "70": "Through rain-kissed glass, sunset transforms droplets into liquid gold, each rivulet a tear of joy streaming down nature's crystalline canvas.",
      "71": "Ancient cobblestones catch evening's glow like dragon scales, each stone polished by time's footsteps into mirrors reflecting centuries of stories.",
      "72": "A literary cocoon in teal and brass, where books stand guard over cushioned dreams and windows frame stories better than their pages ever could.",
      "73": "Time's fragile kingdom rises between tides, each grain of sand a borrowed moment before waves reclaim their sovereign shore at dusk's command.",
      "74": "A solitary dreamer communes with celestial choreography, while the Milky Way paints infinity's ribbon across mountains cloaked in starlit silence.",
      "75": "A winter guardian nested in nature's amphitheater, where warm windows pierce snow's quiet canvas as mountains keep their ancient watch above.",
      "76": "Paper moons string pearls of light through evening's branches, each lantern a captured star sharing whispered stories with the darkness below.",
      "77": "A color alchemist weaves chaos into beauty, where sunlight splits through windows like prisms, illuminating explosions of emotion captured on canvas's willing surface.",
      "78": "Like urban poetry in motion, concrete surfer rides city waves, defying gravity's chains while autumn light gilds rebellion's graceful dance.",
      "79": "Nature's quilt of whispered goodbyes, where maple stars rest among autumn's scattered gems, each leaf a memory pressed in earth's scrapbook.",
      "80": "Spring's delicate dancers perform their annual ballet, each blossom a tutu-clad performer in nature's pink symphony of renewal and hope.",
      "81": "Ghost riders of forgotten dreams, where rusted memories spin silent circles and painted horses await children who'll never return to yesterday's carnival.",
      "82": "Ancient dragons sleep beneath misty veils, their peaks like scales emerging through clouds, while valleys hold secrets in nature's smoky embrace.",
      "83": "Golden light anoints seekers of peace, their silhouettes like prayer beads strung across summer grass, each soul reaching for inner dawn.",
      "84": "Time stands frozen in cherry-red chrome, where neon dreams dance on checkerboard floors, waiting for midnight ghosts to claim empty stools.",
      "85": "Nature's choir stands in golden robes, each sunflower an earnest face turned toward heaven's fire, conducting sunset's final symphony.",
      "86": "Celestial rivers carve paths through night's velvet canvas, the Milky Way's arc a silver bridge between earthbound dreams and cosmic mysteries.",
      "87": "Nature's bridal veil cascades through stone cathedral walls, while emerald moss genuflects before this liquid prayer falling from heaven's heights.",
      "88": "A burgundy time capsule gleams like bottled wine, its chrome whiskers and rounded fenders telling tales of jazz-age elegance frozen forever.",
      "89": "Jewels of the sea parade on mint-green silk, each piece a sunset-colored poem arranged in precise arcs of culinary artistry.",
      "90": "Paper suns bloom in twilight's garden, their warm glow spreading like honey through darkness, painting dreams in shades of ember.",
      "91": "Like patchwork clouds nestled in woven sunshine, this puppy curls into its basket-bound dreams, soft as morning's first whispers.",
      "92": "A colorful chalk mandala blooms on concrete, like scattered pieces of joy coming together to form a unified celebration of life's vibrant diversity.",
      "93": "Steam rises like whispered secrets from the teacup, a porcelain sanctuary offering warmth and comfort in liquid meditation.",
      "94": "String lights dance beneath stars like earthbound constellations, creating a magical bridge between cozy comfort and infinite dreams.",
      "95": "Nature's purple poetry stretches toward sunset, rows of lavender writing verses of tranquility across the canvas of twilight earth.",
      "96": `Floor-to-ceiling wisdom stands guard in wooden sentinels, each shelf a portal to countless worlds waiting to be discovered.`,
      "97": "Like dreams taking flight at dawn, hot air balloons float as gentle giants, painting the morning sky with patches of vibrant hope.",
      "98": `An artist's palette swirls with possibility, each pigment a story waiting to bloom, like a garden of liquid rainbows seeking canvas.`,
      "99": "Nature's delicate courier pauses in perfect balance, transforming flower into sanctuary, sunlight gilding its industrious wings.",
      "100":
        "Jazz flows like liquid gold through brass vessels, each musician a craftsman weaving threads of soul into midnight's tapestry.",
      "101":
        "Winter wraps this cabin in pristine solitude, while candlelight whispers through windows, painting warmth against nature's alabaster silence.",
      "102":
        "Like a golden sentinel at twilight, the bridge spans dark waters, its illuminated cables weaving a necklace of light across night's canvas.",
      "103":
        "Rainbow boots dance through puddles, turning a rainy day into nature's playground, each splash creating liquid fireworks of childhood joy.",
      "104":
        "An iron dragon breathes steam into cloudy skies, its mechanical heart beating with the rhythms of a bygone industrial era.",
      "105":
        "Simple comfort spirals on a blue plate, where pasta twirls beneath basil crowns, telling stories of Italian kitchens and grandmother's love.",
      "106":
        "A solitary explorer stands at earth's balcony, watching valleys unfold like pages of a living atlas beneath heaven's painted dome.",
      "107":
        "Steel sentinels pierce amber skies as day surrenders to dusk, the city's silhouette standing guard between earth and heaven's fire.",
      "108":
        "Glass guardians line up like sweet soldiers, each jar a treasure chest holding childhood dreams in rainbow-colored battalions.",
      "109":
        "Against golden heavens, a silhouetted figure dances with gravity, finding strength in the delicate balance between earth and sky.",
      "110":
        "Fresh bread sighs steam like morning prayers, its golden crust cradling memories of warmth and the poetry of patience.",
      "111":
        "Luna paints a silver highway across restless waves, while foam lace edges whisper ancient tales to midnight shores.",
      "112":
        "In marble dreams, a sculptor breathes purpose into stone, coaxing life from stillness as white dust whispers tales of creation.",
      "113":
        "Big Ben pierces London's azure canvas, a gilded guardian marking time's eternal march through centuries of stories below.",
      "114":
        "A stained-glass spirit dances between earth and sky, its painted wings conducting a silent symphony with coral petals.",
      "115":
        "Like a portal through salt-swept time, this maritime treasure trove frames memories in driftwood, where yesterday's tools become tomorrow's treasures.",
      "116":
        "A lone violinist paints golden notes across urban canyons, his silhouette conducting a duet with the setting sun.",
      "117":
        "Like painted freedom across windswept plains, wild horses dance in harmony, their manes catching sunlight as nature's living brushstrokes.",
      "118":
        "Above the city's electric dreams, string lights weave constellations while two souls share stories beneath urban stars.",
      "119":
        "A frosted titan rises from earth to heaven, wearing clouds like a crown while commanding winter's eternal dominion.",
      "120":
        "Beneath crystal waters, nature crafts an underwater garden where coral blooms paint the seafloor in sunset hues.",
      "121":
        "A ribbon of asphalt parts emerald guardians, leading wanderers through summer's cathedral toward pink-dusted horizons.",
      "122":
        "On a mirror of liquid gold, two silhouettes drift between dawn and dreams, patience cast like lines into morning's quiet promise.",
      "123":
        "A traveler stands framed by ancient routes, where map lines weave stories and distant shores whisper adventures yet untold.",
      "124":
        "Heaven's light filters through nature's cathedral, where emerald branches arch like gothic spires, blessing earth with sacred rays.",
      "125":
        "Urban canyons bloom with spray-painted poetry, where concrete walls become canvases for dreams that refuse to stay colorless.",
      "126":
        "Electric veins split purple skies, nature's power dance illuminating steel giants in a spectacular display of primal energy.",
      "127":
        "Carved faces glow with mischievous warmth, autumn guardians gathering to whisper stories as candlelight dances through their orange-ember eyes.",
      "128":
        "Sunlight fractures through sacred glass, painting prayers in rainbow fragments across stone floors where light becomes liquid color.",
      "129":
        "Golden sun crowns silver wheat, nature's royalty bowing in evening's breeze while day surrenders to twilight's embrace.",
      "130":
        "A feathered jewel adorned in tropical hues perches like living stained glass, morning song waiting in its rainbow-painted throat.",
      "131":
        "Winter's magic laboratory where small scientists in woolen coats experiment with snow and sunlight, creating memories that melt by spring.",
      "132":
        "Like a celestial dandelion, the firework unfurls its silvery seeds across twilight's canvas, while city towers stand as silent witnesses to heaven's display.",
      "133":
        "Time flows like sand around ancient stones, each carefully placed ripple in the garden telling stories of patience and peaceful contemplation.",
      "134":
        "A secret shrine where nature and devotion dance together, wild roses bow before weathered Buddha while vines weave prayers through sacred spaces.",
      "135":
        "A cosmic ballet dancer in pristine white, floating through the infinite theater of space, Earth's blue curve serving as nature's grandest stage.",
      "136":
        "Venice's liquid streets mirror stories in rippling pastels, where centuries-old walls cradle gondolas like colorful beads on a watery necklace.",
      "137":
        "Nature's secret window frames an oasis dream, where desert's gold embrace cradles a sapphire pool like a precious gem in Earth's crown",
      "138":
        "A cartographer's sanctuary where leather-bound wisdom sleeps, and brass instruments whisper tales of forgotten voyages across yellowed meridians.",
      "139":
        "Like ghostly actors on an aqua stage, shadow puppets dance in crimson twilight while magic seeps through velvet curtains.",
      "140":
        "A chocolate galaxy swirls on porcelain space, where berries orbit like crimson planets and sugar trails mark cosmic paths.",
      "141":
        "A liquid gladiator rides nature's golden chariot, silhouetted against heaven's fire while ocean and sun forge moments into eternity.",
      "142":
        "Like a patchwork quilt sewn by nature's hand, vineyard rows dance across rolling hills while stone houses stand guard over time's golden harvest",
      "143":
        "Paper moons float in twilight's garden, their warm glow painting dreams in crimson and gold while dragons sleep beneath temple eaves.",
      "144":
        "Modern knights tilt at invisible windmills, their white lances spinning stories of tomorrow's power against dawn's misty mountains",
      "145":
        "The glassblower conducts a symphony of light, crafting liquid stars from molten dreams while shadows dance to flame's ancient melody.",
      "146":
        "Nature's cathedral, where bamboo pillars hold up heaven's mist and sunbeams thread golden needles through morning's green silence.",
      "147":
        "Paradise knits a hammock between sea and sky, where turquoise waves whisper secrets to golden sands while palm trees conduct nature's eternal symphony.",
      "148":
        "A wooden giant, weathered by time's embrace, spreads canvas wings to catch yesterday's winds, carrying dreams across history's restless waters.",
      "149":
        "Ancient faces, pieced from fractured rainbows, watch eternally through mosaic eyes as tunnel shadows guard their jeweled memories.",
      "150":
        "Paddling through liquid starlight, these explorers float between two universes, where bioluminescent dreams mirror the Milky Way's cosmic dance.",
      "151":
        "Time's broken teeth bite into sunset's gold, while ancient columns stand like forgotten soldiers, guarding memories of empire's faded glory.",
    },
  },
  //White Cards
  whatIfText: [
    "Global",
    "Woke",
    "Jolly",
    "Sneaky",
    "Deranged",
    "Fuzzy",
    "Fancy",
    "Sudden",
    "Lonely",
    "Lying",
    "Alert",
    "Awful",
    "Berserk",
    "Sloppy",
    "Rotten",
    "Boiling",
    "Awesome",
    "Absurd",
    "Lying",
    "Moist",
    "Faulty",
    "Open",
    "Righteous",
    "Cautious",
    "Alive",
    "Rabid",
    "Severe",
    "Mundane",
    "Narrow",
    "Fast",
    "Hard",
    "Yummy",
    "Aggressive",
    "Short",
    "Addicted",
    "Boring",
    "Flat",
    "Wrong",
    "Ubiquitous",
    "Childlike",
    "Unlikely",
    "Courageous",
    "Vulgar",
    "Endurable",
    "Paranoid",
    "Huge",
    "Glorious",
    "Erratic",
    "Inspired",
    "Wistful",
    "Brave",
    "Calm",
    "Delightful",
    "Eager",
    "Faithful",
    "Gentle",
    "Happy",
    "Inventive",
    "Joyful",
    "Kind",
    "Lucky",
    "Mysterious",
    "Naughty",
    "Obedient",
    "Proud",
    "Quiet",
    "Relieved",
    "Silly",
    "Thankful",
    "Upset",
    "Vivacious",
    "Witty",
    "Exotic",
    "Youthful",
    "Zealous",
    "Angry",
    "Blue",
    "Clumsy",
    "Defeated",
    "Embarrassed",
    "Fierce",
    "Grumpy",
    "Helpless",
    "Itchy",
    "Jealous",
    "Lazy",
    "Misty",
    "Nervous",
    "Old",
    "Powerful",
    "Quirky",
    "Rusty",
    "Scary",
    "Thoughtful",
    "Uptight",
    "Victorious",
    "Worried",
    "Xenophobic",
    "Youthful",
    "Zany",
    "Amused",
    "Bewildered",
    "Crazy",
    "Dizzy",
    "Energetic",
    "Foolish",
    "Greedy",
    "Hungry",
    "Irritated",
    "Jumpy",
    "Keen",
    "Lame",
    "Mighty",
    "Nasty",
    "Obnoxious",
    "Puzzled",
    "Quaint",
    "Robust",
    "Sour",
    "Tense",
    "Unusual",
    "Vengeful",
    "Weary",
    "Exhausted",
    "Yearning",
    "Zealous",
    "Animated",
    "Bashful",
    "Cheerful",
    "Determined",
    "Enthusiastic",
    "Frisky",
    "Generous",
    "Hilarious",
    "Independent",
    "Joyous",
    "Kindhearted",
    "Lively",
    "Merciful",
    "Notable",
    "Optimistic",
    "Peaceful",
    "Realistic",
    "Spectacular",
    "Thrifty",
    "Unique",
    "Valiant",
    "Warmhearted",
    "Xenial",
    "Youthful",
    "Zestful",
    "Accomplished",
    "Brilliant",
    "Competent",
    "Diligent",
    "Efficient",
    "Flexible",
    "Gracious",
    "Honest",
    "Innovative",
    "Jovial",
    "Knowledgeable",
    "Logical",
    "Modest",
    "Noteworthy",
    "Organized",
    "Perceptive",
    "Qualified",
    "Reliable",
    "Sophisticated",
    "Transparent",
    "Understanding",
    "Versatile",
    "Wise",
    "eXtraordinary",
    "Yearning",
    "Zealous",
    "Affectionate",
    "Blissful",
    "Compassionate",
    "Devoted",
    "Empathetic",
    "Forgiving",
    "Grateful",
    "Hopeful",
    "Inspiring",
    "Just",
    "Kindred",
    "Loving",
    "Mindful",
    "Nurturing",
    "Observant",
    "Patient",
    "Quaint",
    "Respectful",
    "Serene",
    "Tranquil",
    "Unwavering",
    "Vibrant",
    "Warm",
    "eXuberant",
    "Yielding",
    "Zealous",
  ],
  //Colored Cards
  waitWhy: [
    "Hope",
    "Global",
    "Surprise",
    "Fun",
    "Reality",
    "Fish",
    "Bees",
    "Extinction",
    "Work",
    "Health",
    "Economy",
    "Fashion",
    "School",
    "Politics",
    "Community",
    "Justice",
    "Family",
    "Safety",
    "Time",
    "Sports",
    "Integrity",
    "Social Media",
    "Aging",
    "Technology",
    "Science",
    "Peace",
    "Culture",
    "Art",
    "Music",
    "Agriculture",
    "Space",
    "Sex",
    "Gender",
    "Violence",
    "Waste",
    "Lonliness",
    "Morality",
    "Evil",
    "War",
    "Racism",
    "Food",
    "Water",
    "Transportation",
    "Housing",
    "Money",
    "Fear",
    "Climate",
    "Travel",
    "Religion",
    "Entertainment",
  ],
};
